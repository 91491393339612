import React, { useState } from "react";
import {Paper, Table, TableBody, Box, Collapse, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography, IconButton, Button} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions"; 
import Chip from "@mui/material/Chip";
import { StyledTableCell, StyledTableRow } from "./Styles/TableAndCellStyles";
import { useNavigate } from 'react-router-dom'; // for navigation
import PaymentIcon from '@mui/icons-material/Payment';
import { useDispatch } from "react-redux";
import { setTenantPayment } from "state";
import { formatDateString } from "utilities/date";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import axios from "axios";
import { baseURL } from "services/API";
import moment from "moment";
import { withoutSymbolFormatter } from "utilities/currencyFormatter";


const LandlordTenantPaymentsTable = ({ paymentData, handleDeletePayment, handleEditPayment }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const paymentMethods = { 1: "CASH", 2: "MOBILE MONEY", 3: "MOMO PAY" };

  const renderStatusPills = (status) => {
		switch (status) {
			case 0:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ffebeb", fontSize:"10px", fontWeight:700 }} label="NO" size="small" color="warning" />;
			default:
				return <Chip variant="outlined" sx={{ backgroundColor: "#ebefff", fontSize:"10px", fontWeight:700 }} label="YES" size="small" color="primary" />;
		}
	};

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleShowReceipt = (payment) => {
    dispatch(setTenantPayment(payment))
    navigate(`/tenant-receipts`);
  }

  function DetailDropDown(props) {
    const { row, index } = props;
    const [open, setOpen] = useState(false);
    const [itemData, setItemData] = useState(null);

    const handleClickView = async (id) => {
      try {
        const response = await axios.get(
          `${baseURL}/tenants/completed_payments/payments_list?period_id=${id}`
        );
        setItemData(response.data.data);
      } catch (err) {}
    };

    const handleClickViewClose = async (e) => {};
    
    return (
      <>
        <StyledTableRow key={row.id}>
          <StyledTableCell>{`${row.related_month?.month} ${row.related_month?.year}`}</StyledTableCell>
          <StyledTableCell>{formatDateString(row.date_started)}</StyledTableCell>
          <StyledTableCell>{formatDateString(row.date_ended)}</StyledTableCell>
          <StyledTableCell style={{ textAlign: "center" }}>{row.total}</StyledTableCell>
          <StyledTableCell style={{ textAlign: "center" }}>{row.count}</StyledTableCell>
          <StyledTableCell style={{ textAlign: "center" }}>{renderStatusPills(row.has_security_deposit)}</StyledTableCell>
          <StyledTableCell style={{ textAlign:"center" }}>
            <IconButton aria-label="expand row"  onClick={(e) => {   setOpen(!open);   handleClickView(row.id); }}> {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
          </StyledTableCell>
          <StyledTableCell style={{ textAlign: "center" }}>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              sx={{ color:"blue" }}
              onClick={(e) => handleShowReceipt(row)}
            >
              <PaymentIcon color="blue" sx={{ mr: 1 }}/>
              View Receipt
            </Button>
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow key={row.id}>
          <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: "10px 10px 10px 10px", width:"100%" }}>
                <Typography variant="h4" gutterBottom component="div"> Payment Details</Typography>
                {itemData ? 
                  <Box sx={{ display: "flex", flexDirection: "column", margin: "15px"  }}>
                    <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                    <TableHead>
                      <StyledTableRow>
                      <StyledTableCell style={{ minWidth: 50,padding: "6px 20px" }}>Payment Date</StyledTableCell>
                      <StyledTableCell style={{ minWidth: 150,padding: "6px 20px", textAlign: "center"  }}>Amount Paid</StyledTableCell>
                      <StyledTableCell style={{ minWidth: 50,padding: "6px 20px", textAlign: "center"  }}>Mode of Payment</StyledTableCell>
                      {/* <StyledTableCell style={{ minWidth: 50,padding: "6px 20px", textAlign: "center" , textAlign:"center" }}>Action</StyledTableCell> */}
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {itemData.length === 0 ? (
                      <StyledTableRow>
                        <StyledTableCell colSpan={4} style={{ textAlign: "center" }}>
                          <Typography variant="h5">No payments recorded</Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                      ) : (
                      itemData
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                        return (
                          <StyledTableRow key={row.id}>
                          <StyledTableCell style={{ padding: "6px 20px" }}>{moment(row.date_paid).format('LL')}</StyledTableCell>
                          <StyledTableCell style={{ padding: "6px 20px", textAlign: "center" }}>{withoutSymbolFormatter(parseFloat(row.amount))}</StyledTableCell>
                          <StyledTableCell style={{ padding: "6px 20px", textAlign: "center"  }}>{paymentMethods[row.payment_method]}</StyledTableCell>
                          {/* <StyledTableCell style={{ padding: "6px 20px", textAlign: "center"  }}>
                            <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={(e) => {handleDeletePayment(row)}}
                            >
                            <PaymentIcon color="action" sx={{ mr: 1 }} />
                            Delete Payment
                            </Button>
                          </StyledTableCell> */}
                          </StyledTableRow>
                        );
                        })
                      )}
                    </TableBody>
                    </Table>
                  </Box> : (
                    <Box sx={{ width:"100%", display:"flex", justifyContent:"center" }}>
                    <h4>Payment Details Loading.......</h4>
                    </Box>
                  )
                }

              </Box>
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      </>
    );
  }

  return (
    <>
      {paymentData.length > 0 ? (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Month Paid For</StyledTableCell>
                  <StyledTableCell>Date Started</StyledTableCell>
                  <StyledTableCell>Date Ending</StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>Amount Paid</StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>Partial Payments</StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>Has Security Payment</StyledTableCell>
                  <StyledTableCell>Payments Made</StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>Actions</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? paymentData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : paymentData
                ).map((payment, index) => (
                  <DetailDropDown row={payment} index={index}/>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, { label: "All", value: -1 }]}
                    colSpan={7}
                    count={paymentData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Typography>No payments available</Typography>
      )}
    </>
  );
};

export default LandlordTenantPaymentsTable;
