// src/App.js
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import LoginPage from "scenes/loginPage";
import ProfilePage from "scenes/profilePage";
import Properties from "scenes/GeneralProperties/Properties";
import Tenants from "scenes/Tenants"
import Users from "scenes/Users"
import Tickets from "scenes/Tickets";
import Payments from "scenes/Payments";
import Dashboard from "scenes/Dashboard";
import Landlords from "scenes/GeneralLandlords/Landlords";
import Brokers from "scenes/Brokers";
import Manager from "scenes/Management/Managers/Managers";
import PropertiesManaged from "scenes/Management/PropertiesManaged.jsx/index.jsx";
import ReferredLandlords from "scenes/GeneralLandlords/ReferredLandlords";
import NotFound from "scenes/404/Notfound";
import LandlordExpenses from "scenes/GeneralLandlords/LandlordExpenses";
import Units from "components/Tables/UnitsTable";
import PropertyDetails from "scenes/GeneralProperties/Properties/propertyDetails";
import BrokerDetails from "scenes/Brokers/BrokerDetails"
import TenantDetails from "scenes/Users/TenantDetails";
import { CssBaseline, ThemeProvider, Box, Fab } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import TenantReceipt from "scenes/Tenants/Receipt";
import BrokerPayments from "scenes/Brokers/BrokerPayments";
import LandlordTenants from "scenes/GeneralLandlords/Landlords/LandlordTenants";
import Booking from "scenes/Booking";
import BookingDetails from "scenes/Booking/BookingDetails";
import LandlordTenantsPayments from "scenes/GeneralLandlords/Landlords/LandlordTenantsPayments";
import LoanRequests from "scenes/TenantsLoans";
import LoanRequestsTable from "components/Tables/LoanRequestsTable";
import LoanReview from "scenes/TenantsLoans/LoanReview";
import UnitDetails from "scenes/GeneralProperties/Properties/unitDetails";
import RegisteredProperties from "scenes/Management/Managers/registeredProperties";
import Sidebar from "scenes/global/NewSidebar";
import Navbar from "scenes/navbar";
import Settings from "scenes/Settings";
import ManagementTab from "scenes/Management";
import GeneralPropertiesTab from "scenes/GeneralProperties";
import LandLordDetailsTab from "scenes/GeneralLandlords/Landlords/landlordTab";
import GeneralLandlordsTab from "scenes/GeneralLandlords";
import PropertyDetailsTab from "scenes/GeneralProperties/Properties/propertyTab";
import RegistrationConfirmation from "scenes/GeneralLandlords/RegisteredLandlords/registrationConfirmation";
import UnitCreate from "scenes/GeneralProperties/Properties/unitCreate";
import ChatIcon from '@mui/icons-material/Chat';
import AddIcon from '@mui/icons-material/Add';

function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const [isSidebar, setIsSidebar] = useState(true);
  const isAuth = Boolean(useSelector((state) => state.token));
  const [openMessages, setOpenMessages] = useState(false);

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {isAuth ? (
            <>
              <Navbar setIsSidebar={setIsSidebar} />
              <Box sx={{ display: "flex", height:"95vh" }}>
                <Sidebar isSidebar={isSidebar} />
                <main className="content">
                  <Box>
                    <Routes>
                      <Route path="/" element={<Navigate to="/home" />} />
                      <Route path="/home" element={<Dashboard />} />
                      <Route path="/tenants" element={<Users />} />
                      <Route path="/saving-tenants" element={<Tenants />} />
                      <Route path="/payments" element={<Payments />} />
                      <Route path="/generalProperties" element={<GeneralPropertiesTab />} />
                      <Route path="/properties" element={<Properties />} />
                      <Route path="/propertyDetailsTab" element={<PropertyDetailsTab />} />
                      <Route path="/units" element={<Units />} />
                      <Route path="/details" element={<PropertyDetails />} />
                      <Route path="/broker-details" element={<BrokerDetails />} />
                      <Route path="/payments" element={<Payments />} />
                      <Route path="/landlords" element={<GeneralLandlordsTab />} />
                      <Route path="/landlordDetails" element={<LandLordDetailsTab />} />
                      <Route path="/brokers" element={<Brokers />} />
                      <Route path="/landlord-expenses" element={<LandlordExpenses />} />
                      <Route path="/managers" element={<Manager />} />
                      <Route path="/referred-landlords" element={<ReferredLandlords />} />
                      <Route path="/properties-managed" element={<PropertiesManaged />} />
                      <Route path="/broker-payments" element={<BrokerPayments />} />
                      <Route path="/Landlord-tenants" element={<LandlordTenants />} />
                      <Route path="/registrationConfirmation" element={<RegistrationConfirmation/>}/>
                      <Route path="/TenantDetails" element={<TenantDetails />} />
                      <Route path="/tenants-loans" element={<LoanRequests />} />
                      <Route path="/tenant-payments" element={<LandlordTenantsPayments />} />
                      <Route path="/tenant-receipts" element={<TenantReceipt />} />
                      <Route path="/bookings" element={<Booking />} />
                      <Route path="/booking-details" element={<BookingDetails />} />
                      <Route path="/loan-review" element={<LoanReview />} />
                      <Route path="/units/details" element={<UnitDetails />} />
                      <Route path="/unitCreate" element={<UnitCreate />} />
                      <Route path="/registered-properties" element={<RegisteredProperties />} />
                      <Route path="/profile/:userId" element={<ProfilePage />} />
                      <Route path="/settings" element={<Settings />} />
                      <Route path="/managment" element={<ManagementTab />} />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </Box>
                </main>
              </Box>
              {/* <Box sx={{  position:'fixed',bottom: 40,right: 20, alignContent:"center" }}>
                {openMessages && <Fab size="small" sx={{  color: 'common.white',bgcolor: "gold",'&:hover': {  bgcolor: "#B59410",},}}>
                  <AddIcon/>
                </Fab>}
                <Fab onClick={()=> setOpenMessages(!openMessages)} sx={{ marginLeft:"5px", color: 'common.white',bgcolor: "gold",'&:hover': {  bgcolor: "#B59410",},}}>
                  <ChatIcon/>
                </Fab>
              </Box> */}
            </>
          ) : (
            <main className="content">
              <Routes>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/login" element={<LoginPage />} />
              </Routes>
            </main>
          )}
          
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
