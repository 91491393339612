import * as React from "react";
import axios from "axios";
import { baseURL } from "services/API";
import { Grid, Box, Typography } from "@mui/material";
import RentalsTable from "../../../components/Tables/RentalsTable";
import DummyTable from "components/Tables/DummyTable";
import FormDialog from "components/modal";
import BasicSelect from "components/select";
import { useRentals } from "services/hooks/rentals";
import RentalsForm from "components/Forms/RentalsForm";
import PropertyPopover from "components/PopOvers/PropertyPopover";
import ProgressScale from "components/ProgressScale";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setRefreshCount } from "state";
import TopSnack from "components/TopSnack";
import { setPropertyId } from "state";
import FlexBetween from "components/FlexBetween";
import * as yup from 'yup';

const Rentals = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const landlordId = useSelector((state) => state.landlord);
  const { rentals, isLoadingRentals, setNewData } = useRentals();
  const [rental, setRental] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [modal, setModal] = React.useState("");
  const [details, setDetails] = React.useState("");
  const [rentalName, setRentalName] = React.useState("");
  const [rentalType, setRentalType] = React.useState("");
  const [rentalLocation, setRentalLocation] = React.useState("");
  const [rentalCity, setRentalCity] = React.useState("");
  const [security, setSecurity] = React.useState(false);
  const [activeRental, setActiveRental] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openPopper = Boolean(anchorEl);
  const [deleteMessage, setDeleteMessage] = React.useState("");
  const [deleteButton, setDeleteButton] = React.useState(false);
  const popperId = openPopper ? "simple-popover" : undefined;
  const [openSnack, setOpenSnack] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [severity, setSeverity] = React.useState("");
  const [message, setMessage] = React.useState("");

  const handleNavigateUnits = (property_id) => {
    dispatch(setPropertyId(property_id));
    navigate("/propertyDetailsTab");
  };

  const handleRentalNameChange = (event) => {
    setRentalName(event.target.value);
  };

  const handleChangeRentalType = (event) => {
    setRentalType(event.target.value);
  };

  const handleRentalLocationChange = (event) => {
    setRentalLocation(event.target.value);
  };

  const handleRentalCityChange = (event) => {
    setRentalCity(event.target.value);
  };

  const handleSecurityCheck = (event) => {
    setSecurity(event.target.checked);
  };

  const handleClickOpen = () => {
    setOpen(true);
    handleClosePopOver();
  };

  const handleClose = () => {
    setOpen(false);
    setModal("");
  };

  const handleClickPopOver = (event, row) => {
    setAnchorEl(event.currentTarget);
    setActiveRental(row);
  };

  const handleClosePopOver = () => {
    setAnchorEl(null);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack({ ...openSnack, open: false });
  };

  const handleModalView = (modalView) => {
    setModal(modalView);
  };

  const handleSubmitDelete = async () => {
    try {
      const response = await axios.delete(
        `${baseURL}/landlords/rentals/delete?rental_id=${activeRental}`
      );

      setOpenSnack({ ...openSnack, open: true });
      setSeverity("success");
      setMessage("Property Deleted Successfully");
      setOpen(false);
      setNewData(true);
    } catch (err) {
      setOpenSnack({ ...openSnack, open: true });
      setSeverity("error");
      setMessage("Property Delete failed, Please check unit details");
    }
  };

  const [errors, setErrors] = React.useState({});
	const propertySchema = yup.object().shape({
		rental_name: yup.string().required("Please enter the property name"),
		location: yup.string().required("Input a parish or county in the selected City"),
		rental_type: yup.string().required("Select the property type"),
	});

  const handleRentalFormSubmit = async () => {

    try {
			await propertySchema.validate({rental_name: rentalName, rental_type: rentalType, 	location: rentalLocation}, {abortEarly:false});
		} catch (error) {
			const newError = {}
			error.inner.forEach(err => {
				newError[err.path]=err.message
			})
			setErrors(newError)
			return
		}

    try {
      const response = await axios.post(`${baseURL}/landlords/rentals`, {
        related_landlord: landlordId,
        rental_name: rentalName,
        rental_type: rentalType,
        location: rentalLocation,
      });

      setOpenSnack({...openSnack, open: true});
      setSeverity("success");
      setMessage("Property Added Successfully");
      setOpen(false);
      dispatch(setRefreshCount());
    } catch (err) {
      setOpenSnack({ ...openSnack, open: true });
      setSeverity("error");
      setMessage("Property Addition failed, Please check property details");
    }
  };

  return (
    <Box m="20px">
      <Grid container spacing={2}>
        <Grid item xs={12} spacing={2}>
          {isLoadingRentals && <ProgressScale />}
          <FlexBetween>
            <Typography fontWeight="bold" fontSize="25px">
              Properties
            </Typography>
            {/* <Button
              onClick={() => {
                setModal("create");
                handleClickOpen();
              }}
              variant="contained"
              color="primary"
            >
              Add Property
            </Button> */}
          </FlexBetween>

          <Grid container spacing={2}>
            <Grid
              item
              sx={{ display: { xs: "none", sm: "block" } }}
              sm={7}
              md={4}
            >
              {/* <BasicSelect value={rental} label={"Properties"} data={rentals} /> */}
            </Grid>

            <Grid
              item
              sx={{ display: { xs: "block", sm: "none" } }}
              xs={12}
              sm={4}
            >
              <BasicSelect value={rental} label={"Properties"} data={rentals} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ marginTop: 3 }}>
              {isLoadingRentals ? (
                <DummyTable />
              ) : (
                <RentalsTable
                  setDetails={setDetails}
                  rows={rentals}
                  isLoadingRentals={isLoadingRentals}
                  handleClickPopOver={handleClickPopOver}
                  handleNavigateUnits={handleNavigateUnits}
                  id={popperId}
                />
              )}
            </Grid>
          </Grid>
          <PropertyPopover
            popperId={popperId}
            openPopper={openPopper}
            anchorEl={anchorEl}
            handleClosePopOver={handleClosePopOver}
            handleClickOpen={handleClickOpen}
            navigate={navigate}
            activeRental={activeRental}
            handleModalView={handleModalView}
            handleNavigateUnits={handleNavigateUnits}
            setDeleteButton={setDeleteButton}
            setDeleteMessage={setDeleteMessage}
          />
          {modal === "delete" ? (
            <FormDialog
              title="Delete Property"
              enableActions={deleteButton}
              open={open}
              content={deleteMessage}
              handleClose={handleClose}
              handleSubmit={handleSubmitDelete}
              buttonText="Delete"
            />
          ) : modal === "create" ? (
            <FormDialog
              open={open}
              handleClose={handleClose}
              enableActions={true}
              content={
                <RentalsForm
                  rentalType={rentalType}
                  rentalCity={rentalCity}
                  handleRentalNameChange={handleRentalNameChange}
                  handleChangeRentalType={handleChangeRentalType}
                  handleRentalLocationChange={handleRentalLocationChange}
                  handleRentalCityChange={handleRentalCityChange}
                  handleSecurityCheck={handleSecurityCheck}
                  errors={errors}
                  security={security}
                />
              }
              title={"Add all Property Details"}
              handleSubmit={handleRentalFormSubmit}
              buttonText="submit"
            />
          ) : null}

          <TopSnack
            vertical={openSnack.vertical}
            horizontal={openSnack.horizontal}
            open={openSnack.open}
            handleCloseSnack={handleCloseSnack}
            message={message}
            severity={severity}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Rentals;
