import React, { useState } from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, IconButton, Menu, MenuItem, Box, Divider} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PaymentIcon from '@mui/icons-material/Payment';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useNavigate } from 'react-router-dom'; // for navigation
import { StyledTableRow, StyledTableCell } from './Styles/TableAndCellStyles';
import { formatDate } from 'utilities/date';
import { useDispatch } from 'react-redux';
import { setTenantOccupancy } from 'state';
import { formatDateString } from 'utilities/date';

const TablePaginationActions = (props) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

const LandlordPropertiesTable = ({ properties, page, rowsPerPage, handleChangePage,anchorEl, setAnchorEl, handleChangeRowsPerPage, handleClickOpenDelete, handleNavigateUnits }) => {

  const navigate = useNavigate(); // use for navigation
  const dispatch = useDispatch();
  const [selectedTenant, setSelectedTenant] = useState(null);

  const handleMenuClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedTenant(item);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedTenant(null);
  };

  const handleDispatchPayments = () => {
    dispatch(setTenantOccupancy(selectedTenant))
    navigate(`/item-payments`);
  }

  return (
    <TableContainer component={Paper}>
      {<Table>
          <TableHead>
            
            <TableRow style={{backgroundColor: '#FEF0DE'}}>
              <StyledTableCell>#</StyledTableCell>
              <StyledTableCell>Rental Name</StyledTableCell>
              <StyledTableCell>Rental Type</StyledTableCell>
              <StyledTableCell>Rental Size</StyledTableCell>
              <StyledTableCell>Location</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {properties && properties
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => (
                <StyledTableRow key={item.id}>
                  <StyledTableCell>{index+1}</StyledTableCell>
                  <StyledTableCell>{item.rental_name}</StyledTableCell>
                  <StyledTableCell>{item.rental_type}</StyledTableCell>
                  <StyledTableCell>{item.units_count}</StyledTableCell>
                  <StyledTableCell>{item.location}</StyledTableCell>
                  <StyledTableCell>
                    <IconButton
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(e) => handleMenuClick(e, item)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      {/* <MenuItem onClick={() => navigate("/units", { state: { id: item.id } })}>
                        View Units 
                      </MenuItem> */}
                      <MenuItem onClick={() => handleNavigateUnits(selectedTenant.id)}>
                        View Property Details
                      </MenuItem>
                      <Divider/>
                      <MenuItem onClick={() => handleClickOpenDelete(selectedTenant)} sx={{ color: 'red' }}>
                        <DeleteIcon sx={{ mr: 1, color: 'red' }} />
                        Delete Property
                      </MenuItem>
                    </Menu>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={properties.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </TableContainer>
  );
};

export default LandlordPropertiesTable;
