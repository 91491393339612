import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


export default function BasicCard({name, value}) {
  return (
    <Card sx={{ minWidth:190 }}>
      <CardContent>
        <Typography variant="h5" component="div">
            {name}
        </Typography>
        <Typography variant="h4">
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
}