import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import axios from "axios";
import { baseURL } from "services/API";
import { Typography } from "@mui/material";


export default function PropertyPopover({popperId,openPopper,anchorEl,handleClosePopOver,handleClickOpen,navigate,activeRental,handleModalView,handleNavigateUnits, setDeleteButton, setDeleteMessage}) {
  
  const handleAction = async (modalview) => {
      try {
        const response = await axios.get(`${baseURL}/landlords/rentals/delete?rental_id=${activeRental}`);
        if (response.data.status===200) {
          setDeleteMessage("Are you sure you want to delete this property?");
          setDeleteButton(true);
        }

        if (response.data.status===404) {
          setDeleteMessage(
          <>
            <Typography fontWeight={"bold"} color="red">This property can not currenty be deleted.</Typography>
            <Typography>Please remove any current tenants and try again</Typography>
          </>);
          setDeleteButton(false);
        }
      } catch (err) {
          setDeleteMessage(
          <>
            <Typography fontWeight={"bold"} color="red">This action can not be carried out currenty..</Typography>
            <Typography>Please try again later.</Typography>
          </>);
          setDeleteButton(false);
      }
    handleModalView(modalview);
    handleClickOpen();
    handleClosePopOver();
  };

  return (
    <div style={{ zIndex: 20000 }}>
      <Popover
        id={popperId}
        open={openPopper}
        anchorEl={anchorEl}
        onClose={handleClosePopOver}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Stack sx={{ padding: "10px", alignContent: "left" }}>
          {/* <Button
            sx={{ color: "black", padding: "10px" }}
            onClick={() => navigate("/units", { state: { id: activeRental } })}
          >
            <RemoveRedEyeIcon sx={{ fontSize: "16px", marginRight: "5px" }} />
            View Units
          </Button>
          <Divider /> */}
          <Button
            sx={{ color: "black", padding: "10px" }}
            onClick={() => handleNavigateUnits(activeRental)}
          >
            View Property Details
          </Button>
          <Divider />
          {/* Uncomment if you want to add functionality for editing property */}
          {/* <Button sx={{ color: "black", padding: "10px" }} onClick={() => handleAction("edit")}>
            Edit Property
          </Button> */}
          <Divider />
          <Button
            sx={{ color: "red", padding: "10px" }}
            onClick={() => handleAction("delete")}
          >
            Delete Property
          </Button>
        </Stack>
      </Popover>
    </div>
  );
}
