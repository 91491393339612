import * as React from "react";
import axios from "axios";
import { baseURL } from "services/API";
import { Grid, Box, Typography } from "@mui/material";
import UnasignedPropertiesTable from "components/Tables/UnassignedPropertiesTable";
import DummyTable from "components/Tables/DummyTable";
import FormDialog from "components/modal";
import AssignPropertyForm from "components/Forms/AssignPropertiesForm";
import {useUnAssignedProperties } from "services/hooks/rentals";
import RentalsForm from "components/Forms/RentalsForm";
import UnassignedPropertyPopover from "components/PopOvers/UnassignedPropertyPopover";
import ProgressScale from "components/ProgressScale";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {setRefreshCount } from "state";
import TopSnack from "components/TopSnack";
import { setPropertyId } from "state";
import moment from "moment";

const sidebarWidth = 240; // Adjust based on your sidebar width

const UnassignedProperties = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const landlordId = useSelector((state) => state.landlord);
  const {unAssignedProperties, isLoadingUnAssignedProperties, errorUnAssignedProperties, refetch} = useUnAssignedProperties();
  const [rental, setRental] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openAssignmentForm, setOpenAssignmentForm] = React.useState(false);
  const [modal, setModal] = React.useState("");
  const [details, setDetails] = React.useState("");
  const [rentalName, setRentalName] = React.useState("");
  const [rentalType, setRentalType] = React.useState("");
  const [rentalLocation, setRentalLocation] = React.useState("");
  const [rentalCity, setRentalCity] = React.useState("");
  const [security, setSecurity] = React.useState(false);
  const [activeRental, setActiveRental] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mangerId, setManagerId] = React.useState("");
  const openPopper = Boolean(anchorEl);
  const [deleteMessage, setDeleteMessage] = React.useState("");
  const [deleteButton, setDeleteButton] = React.useState(false);
  const popperId = openPopper ? "simple-popover" : undefined;
  const [openSnack, setOpenSnack] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [severity, setSeverity] = React.useState("");
  const [message, setMessage] = React.useState("");

  const handleManagerChange = (event) =>{
    setManagerId(event.target.value);
  }

  const handleRentalNameChange = (event) => {
    setRentalName(event.target.value);
  };

  const handleNavigateUnits = (property_id) => {
    dispatch(setPropertyId(property_id));
    navigate("/propertyDetailsTab");
  };

  const handleChangeRentalType = (event) => {
    setRentalType(event.target.value);
  };

  const handleRentalLocationChange = (event) => {
    setRentalLocation(event.target.value);
  };

  const handleRentalCityChange = (event) => {
    setRentalCity(event.target.value);
  };

  const handleSecurityCheck = (event) => {
    setSecurity(event.target.checked);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setModal("");
    setOpen(false);
  };

  const handleOpenAssignmentForm = () => {
    setAnchorEl(null);
    setOpenAssignmentForm(true);
  };

  const handleCloseAssignmentForm = () => {
    setModal("");
    setOpenAssignmentForm(false);
  };

  const handleClickPopOver = (event, row) => {
    setAnchorEl(event.currentTarget);
    setActiveRental(row);
  };

  const handleClosePopOver = () => {
    setAnchorEl(null);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack({ ...openSnack, open: false });
  };

  const handleModalView = (modalView) => {
    setModal(modalView);
  };
  
  const handleSubmitDelete = async () => {
    try {
      const response = await axios.delete(
        `${baseURL}/landlords/rentals/delete?rental_id=${activeRental}`
      );

      setOpenSnack({ ...openSnack, open: true });
      setSeverity("success");
      setMessage("Property Deleted Successfully");
      setOpen(false);
      refetch();
    } catch (err) {
      setOpenSnack({ ...openSnack, open: true });
      setSeverity("error");
      setMessage("Property Delete failed, Please check unit details");
    }
  };

  const handleRentalFormSubmit = async () => {
    try {
      console.log(landlordId, rentalName, rentalType, rentalLocation);
      const response = await axios.post(`${baseURL}/landlords/rentals`, {
        related_landlord: landlordId,
        rental_name: rentalName,
        rental_type: rentalType,
        location: rentalLocation,
      });

      setOpenSnack({...openSnack, open: true});
      setSeverity("success");
      setMessage("Property Added Successfully");
      setOpen(false);
      dispatch(setRefreshCount());
    } catch (err) {
      setOpenSnack({ ...openSnack, open: true });
      setSeverity("error");
      setMessage("Property Addition failed, Please check property details");
    }
  };

  const handleAssignmentFormSubmit = async () => {
    try {
      const response = await axios.post(`${baseURL}/admin/managers/properties`, {
        related_manager: mangerId,
        related_property: details.id,
        date_started: moment().format("YYYY-MM-DD"),
      });
      refetch()
      setOpenSnack({...openSnack, open: true});
      setSeverity("success");
      setMessage("Property Assigned Successfully");
      setOpenAssignmentForm(false);
      dispatch(setRefreshCount());
      handleCloseAssignmentForm();
    } catch (err) {
      setOpenSnack({ ...openSnack, open: true });
      setSeverity("error");
    }
  };

  return (
    <Box m="20px">
      <Grid container spacing={2}>
        <Grid item xs={12} spacing={2}>
          {isLoadingUnAssignedProperties && <ProgressScale />}

          <Typography fontWeight="bold" fontSize="25px">
            Unassigned Properties
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ marginTop: 3 }}>
              {isLoadingUnAssignedProperties ? (
                <DummyTable />
              ) : (
                <UnasignedPropertiesTable
                  setDetails={setDetails}
                  rows={unAssignedProperties}
                  isLoadingRentals={isLoadingUnAssignedProperties}
                  handleClickPopOver={handleClickPopOver}
                  id={popperId}
                />
              )}
            </Grid>
          </Grid>
          <UnassignedPropertyPopover
            popperId={popperId}
            openPopper={openPopper}
            anchorEl={anchorEl}
            handleClosePopOver={handleClosePopOver}
            handleClickOpen={handleClickOpen}
            navigate={navigate}
            activeRental={activeRental}
            handleModalView={handleModalView}
            handleOpenAssignmentForm={handleOpenAssignmentForm}
            handleNavigateUnits={handleNavigateUnits}
            setDeleteButton={setDeleteButton}
            setDeleteMessage={setDeleteMessage}
          />

          {/* <FormDialog
            open={open}
            handleClose={handleClose}
            enableActions={true}
            content={
              <RentalsForm
                rentalType={rentalType}
                rentalCity={rentalCity}
                handleRentalNameChange={handleRentalNameChange}
                handleChangeRentalType={handleChangeRentalType}
                handleRentalLocationChange={handleRentalLocationChange}
                handleRentalCityChange={handleRentalCityChange}
                handleSecurityCheck={handleSecurityCheck}
                security={security}
              />
            }
            title={"Add all Property Details"}
            handleSubmit={handleRentalFormSubmit}
            buttonText="submit"
          /> */}
          {modal === "delete" ? (
            <FormDialog
              title="Delete Property"
              enableActions={deleteButton}
              open={open}
              content={deleteMessage}
              handleClose={handleClose}
              handleSubmit={handleSubmitDelete}
              buttonText="Delete"
            />
          ) : modal === "assignProperty" ? (
            <FormDialog
              open={open}
              handleClose={handleClose}
              enableActions={true}
              content={
                <AssignPropertyForm
                  handleManagerChange={handleManagerChange}
                  property={details}
                />
              }
              title={"Assign Property Manager"}
              handleSubmit={handleAssignmentFormSubmit}
              buttonText="submit"
            />
          ) : null}

          <TopSnack
            vertical={openSnack.vertical}
            horizontal={openSnack.horizontal}
            open={openSnack.open}
            handleCloseSnack={handleCloseSnack}
            message={message}
            severity={severity}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UnassignedProperties;
