import React, { useState } from "react";
import {Paper, Table, TableBody, TableContainer, TableHead, TableRow, IconButton, TablePagination, TableFooter} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { StyledTableCell, StyledTableRow } from "./Styles/TableAndCellStyles";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions"; 

// LandlordsTable component
const LandlordsTable = (props) => {
  const {data} = props
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  
  const handleAction = (event, rowid, details) => {
    props.setSelectedLandlord(details);
    props.handleClickPopOver(event, rowid);
  };


  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>#</StyledTableCell>
              <StyledTableCell>First Name</StyledTableCell>
              <StyledTableCell>Last Name</StyledTableCell>
              <StyledTableCell>Username</StyledTableCell>
              <StyledTableCell>Phone Number</StyledTableCell>
              <StyledTableCell>Business Name</StyledTableCell>
              <StyledTableCell>Pay Day</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((landlord, index) => (
                <StyledTableRow key={landlord.id}>
                  <StyledTableCell>{index+1}</StyledTableCell>
                  <StyledTableCell>{landlord.related_user.first_name}</StyledTableCell>
                  <StyledTableCell>{landlord.related_user.last_name}</StyledTableCell>
                  <StyledTableCell>{landlord.related_user.username}</StyledTableCell>
                  <StyledTableCell>{landlord.related_user.phone_number}</StyledTableCell>
                  <StyledTableCell>{landlord.business_name}</StyledTableCell>
                  <StyledTableCell>{landlord.pay_day}</StyledTableCell>
                  <StyledTableCell>
                    <IconButton
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(e) => handleAction(e, landlord.id,landlord)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
          <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, { label: "All", value: 1000 }]}
                    colSpan={7}
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default LandlordsTable;
