import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Grid } from "@mui/material";

export default function TicketUpdateForm(props) {
  return (
    <Grid container
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
    <Grid item xs={12} spacing={2}>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Update Status</InputLabel>
            <Select
            labelId="rental-type"
            id="rental-type-id"
            value={props.rental}
            label="Age"
            onChange={props.handleChangeRental}
            >
                <MenuItem value="1">IN PROGRESS</MenuItem>
                <MenuItem value="2">FIXED</MenuItem>
            </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}