import React, { useState } from 'react';
import { Button, Grid, Typography, Box, TextField } from '@mui/material';
import AddTenantForm from 'components/Forms/TenantsAddForm';
import TenantTable from 'components/Tables/LandlorTenantsTable';
import { styled } from '@mui/material/styles';
import { useTenants } from 'services/hooks/rentals';
import { useSelector, useDispatch } from 'react-redux';
import { setTenantOccupancy } from 'state';
import { useNavigate } from "react-router-dom"; 
import FlexBetween from "components/FlexBetween";
import moment from "moment";
import * as yup from "yup";
import axios from 'axios';
import { baseURL } from 'services/API';
import FormDialog from 'components/modal';


const TitleContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const LandlordTenants = () => {
  const landlord = useSelector((state)=> state.landloard)
  const navigate = useNavigate(); // use for navigation
  const dispatch = useDispatch();
  const { tenants, loading, error } = useTenants(landlord.id); // Use the hook here
  const [openDialog, setOpenDialog] = useState(false);
  const [endTenancyData, setEndTenancyData] = React.useState({
      unit_id: "",
      tenant_id: "",
      date_ended: moment().format('YYYY-MM-DD'),
      reason_ended: "",
    });
  const [errors, setErrors] = React.useState({});
  const [details, setDetails] = React.useState("");
  const [open, setOpen] = React.useState(false);

  // const [tenants, setTenants] = useState(fetchedTenants || null); // Local state for tenants
  const [tenant, setTenant] = useState({
    name: '',
    contact: '',
    amountSaved: '',
    date: '',
    landlordName: '',
    landlordContact: '',
    email: '',
    address: '',
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTenant, setSelectedTenant] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleClose = () => {
    setOpen(false);
    setSelectedTenant(null);
    setEndTenancyData({
      unit_id: "",
      tenant_id: "",
      date_ended: moment().format("YYYY-MM-DD"),
      reason_ended: "",
    });
  };
    

  const handleMenuClick = (event, tenant) => {
    setAnchorEl(event.currentTarget);
    setSelectedTenant(tenant);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDispatchPayments = () => {
    dispatch(setTenantOccupancy(selectedTenant))
    navigate(`/tenant-payments`);
  }

  const handleMenuAction = (action) => {
    if (selectedTenant) {
      switch (action) {
        case 'Tenant Receipt':
          navigate(`/tenant-receipts`);
          break;
        case 'Edit':
          navigate(`/tenant/${selectedTenant.id}/edit`);
          break;
        case 'Payments':
          navigate(`/tenant-payments`);
          break;
        case 'Delete':
          setOpen(true)
          break;
        default:
          break;
      }
    }
    handleMenuClose();
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };


  const endTenancySchema = yup.object().shape({
		unit_id: yup.number().required("This is required"),
		tenant_id: yup.number().required("This is required"),
		date_ended: yup.date().required("This is required"),
		reason_ended: yup.string().required("This is required"),
	})

	const handleChangeEndTenancyData = (event) => {
		const {name, value} = event.target;
		setEndTenancyData((prev) => ({ ...prev, [name]: value }));
		if (!value.trim()) {
		setErrors((prev) => ({ ...prev, [name]: `This is required`}));
		} else {
		setErrors((prev) => ({ ...prev, [name]: '' }));
		}

	}

	const handleSubmitEndTenancy = async () => {
    console.log(selectedTenant)
		try {
			await endTenancySchema.validate({
				unit_id: selectedTenant.related_rental_unit.id,
				tenant_id: selectedTenant.related_tenant.id,
				date_ended:endTenancyData.date_ended,
				reason_ended:endTenancyData.reason_ended
			}, {abortEarly:false});
		} catch (error) {
			const newError = {}
			error.inner.forEach(err => {
				newError[err.path]=err.message
			})
			setErrors(newError)
			return
		}

		try {
			await axios.post(`${baseURL}/landlords/rentals/units/remove`, {
				unit_id: selectedTenant.related_rental_unit.id,
				tenant_id: selectedTenant.related_tenant.id,
				date_ended:endTenancyData.date_ended,
				reason_ended:endTenancyData.reason_ended
			});
			
			handleClose();
			setErrors({});
		} catch (error) {
			console.log(error.response?.data?.error); 
		}
	}

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error loading tenants</Typography>;

  return (
    <Box m="20px">
      {/* <Grid container spacing={2} style={{ marginTop: '20px' }}>
        <Grid item xs={12}>
          <TitleContainer>
            <Button variant="contained" color="primary" disabled={true} onClick={handleOpenDialog}>
              Add new Landlord Tenant
            </Button>
          </TitleContainer>
        </Grid>
      </Grid> */}
      {tenants && <TenantTable
        tenants={tenants} 
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        anchorEl={anchorEl}
        handleMenuClick={handleMenuClick}
        handleMenuAction={handleMenuAction}
        handleMenuClose={handleMenuClose}
        handleDispatchPayments={handleDispatchPayments}
      />}
      <AddTenantForm
        open={openDialog}
        onClose={handleCloseDialog}
        // onSubmit={handleAddTenant}
        tenant={tenant}
        setTenant={setTenant}
      />

      <FormDialog
        title="End Tenancy"
        enableActions={true}
        open={open}
        content={
          <>
            <Typography color="red">Please input the following details to confirm the end of the tenancy</Typography>
            <TextField
              required
              id="outlined-required"
              label="Reason for Ending Tenancy"
              name="reason_ended"
              value={endTenancyData.reason_ended}
              multiline
              rows={3}
              onChange={handleChangeEndTenancyData}
              fullWidth={true}
              margin="dense"
              error={Boolean(errors.reason_ended)}
              helperText={errors.reason_ended}
              autoFocus
            />
            <FlexBetween>
              <Typography> Date of Tenancy End:</Typography>
              <TextField
                required
                id="outlined-required"
                name="date_ended"
                type="date"
                label="Tenancy End Date"
                value={endTenancyData.date_ended}
                onChange={handleChangeEndTenancyData}
                sx={{ width:"50%" }}
                margin="dense"
                error={Boolean(errors.date_ended)}
                helperText={errors.date_ended}
              />
            </FlexBetween>
            
          </>
        }
        handleSubmit={handleSubmitEndTenancy}
        handleClose={handleClose}
        buttonText="Yes, End Tenancy"
      />
    </Box>
  );
};

export default LandlordTenants;
