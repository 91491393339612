import React from "react";
import {Box,Grid,MenuItem,Select,FormControl,InputLabel,TextField,CircularProgress, FormControlLabel, Checkbox} from "@mui/material";


const AddTenantPaymentForm = ({
  monthOptions,
  isLoadingMonthsAndYears,
  handleChangeAmount,
  handleChangeMonth,
  month,
  handleChangeDatePaid,
  handleChangeNextPaymentDate,
  handleChangePaymentMethod,
  paymentMethod,
  handleInitialPayment,
  datePaid,
  initialPayment,
  handleSecurityDeposit,
  hasSecurityDeposit,
  errors
}) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {/* Row 2 */}
        <Grid item xs={6}>
          <TextField
            label="Amount Paid"
            type="number"
            name="amount"
            onChange={handleChangeAmount}
            variant="outlined"
            fullWidth
            error={Boolean(errors.amount)}
            helperText={errors.amount}
          />
        </Grid>

        <Grid item xs={6}>
          {isLoadingMonthsAndYears ? (
            <CircularProgress size={24} sx={{ ml: 2 }} />
          ) : (
            <TextField
              labelId="related-month-label"
              name="related_month"
              select
              fullWidth
              error={Boolean(errors.related_month)}
              helperText={errors.related_month}
              value={month}
              label="Month Paid For"
              onChange={handleChangeMonth}
            >
              {monthOptions.map((value, index) => {
                return (
                  <MenuItem key={index} value={value.id}>
                    {value.month} - {value.year}
                  </MenuItem>
                );
              })}
            </TextField>
          )}
        </Grid>

        {/* Row 3 */}
        <Grid item xs={6}>
          <TextField
            label="Payment Method"
            name="payment_method"
            select
            fullWidth
            error={Boolean(errors.payment_method)}
            helperText={errors.payment_method}
            value={paymentMethod}
            onChange={handleChangePaymentMethod}
          >
            <MenuItem value={1}>Cash</MenuItem>
            <MenuItem value={2}>Mobile Money</MenuItem>
            <MenuItem value={3}>Momo Pay</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Date Paid"
            type="date"
            name="date_paid"
            value={datePaid}
            onChange={handleChangeDatePaid}
            variant="outlined"
            fullWidth
            error={Boolean(errors.date_paid)}
            helperText={errors.date_paid}
          />
        </Grid>

        <Grid item xs={6}>
          <FormControlLabel
            label="Is this an initial Payment?"
            control={
              <Checkbox
                checked={initialPayment}
                onChange={handleInitialPayment}
              />
            }
          />
        </Grid>

        {initialPayment && <Grid item xs={12}>
          <FormControlLabel
            label="Does this initial payment have a security deposit?"
            control={
              <Checkbox
                checked={hasSecurityDeposit}
                onChange={handleSecurityDeposit}
              />
            }
          />
        </Grid>}
      </Grid>
    </Box>
  );
};

export default AddTenantPaymentForm;
