import React, { useState } from "react";
import axios from "axios";
import FormDialog from "components/modal";
import { baseURL } from "services/API";
import { Box, Card, CardContent, Typography, Grid, FormControl, InputLabel, Select, MenuItem, Button, Snackbar, Alert} from "@mui/material";
import LandlordDetailsTable from "components/Tables/LandlordDetailsTable";
import AddPaymentForm from "components/Forms/LandlordPaymentForm";
import { FlexBetween, Flex } from "components/FlexBox/FlexBox";
import BasicCard from "components/Card";
import { withoutSymbolFormatter } from "utilities/currencyFormatter";
import { useLandlordPayments } from "services/hooks/rentals";
import { useSelector } from "react-redux";
import * as yup from 'yup';
import moment from "moment";
import EditLandlorPayments from "components/LandlordPaymentsModal/EditLandlordPayments";

const LandlordPaymentDetails = () => {
  const [page, setPage] = useState(0);
  const [filterMonth, setFilterMonth] = useState("All Months");
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [severity, setSeverity] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [newPayment, setNewPayment] = useState({
    related_landlord_rental: undefined, // Ensure this field is included in the state
    amount: 0,
    description: "",
    date_paid: moment().format('YYYY-MM-DD'),
    related_month: "",
    paid_by: undefined,
  });
  const [selectedLandlordPayment, setSelectedLandlordPayment] = React.useState(null);
  
  const [openSnack, setOpenSnack] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [errors, setErrors] = useState({});
  const [isSubmittingData, setIsSubmittingData] = useState(false);
  const [monthsAndYears, setMonthsAndYears] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false); // State for Snackbar
  const landlord = useSelector((state) => state.landloard); 
  const { landlordPayments, isLoadingLandlordPayments, errorLandlordPayments, setNewItemData } = useLandlordPayments(landlord.id);

  const rowsPerPage = 5;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleFilterMonthChange = (event) => {
    setFilterMonth(event.target.value);
  };

  const handleMenuClick = (event, landlordPayment) => {
    setAnchorEl(event.currentTarget);
    setSelectedLandlordPayment(landlordPayment);
  };

  const handleMenuClose = () => {
    setSelectedLandlordPayment(null);
    setAnchorEl(null);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };

  const handleEditDialogOpen = () => {
    setSelectedLandlordPayment({
      id: selectedLandlordPayment.id,
      related_landlord_rental: selectedLandlordPayment.related_landlord_rental.id, // Ensure this field is included in the state
      amount: selectedLandlordPayment.amount,
      description: selectedLandlordPayment.description,
      date_paid: moment(selectedLandlordPayment.date_paid).format("YYYY-MM-DD"),
      related_month: selectedLandlordPayment.related_month.id
    });
    setEditDialogOpen(true);
    setAnchorEl(null);
  };
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };
  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
    setAnchorEl(null);
  };

  const handleDeletePayment = async () => {
    try {
      const response = await axios.delete(`${baseURL}/admin/landlord_payments/details/${selectedLandlordPayment.id}`);

      setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
      setSeverity("success");
      setMessage("Payment deleted successfully");
      setNewItemData();
      setAnchorEl(null);
    } catch (error) {
      console.error("Error deleting payment:", error);

      setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
      setSeverity("error");
      setMessage("Error deleting payment");
    }
    setDeleteDialogOpen(false);
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewPayment((prev) => ({...prev, [name]: value,}));
    if (!toString(value).trim()) {
      setErrors((prev) => ({ ...prev, [name]: `${name} is required` }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };



  const handleEditInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedLandlordPayment((prevState) => ({...prevState,[name]: value, }));
    if (!toString(value).trim()) {
      setErrors((prev) => ({ ...prev, [name]: `${name} is required` }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    const selectedMonthId =
      monthsAndYears.find(
        (month) => `${month.month} ${month.year}` === selectedMonth
      )?.id || "";
    setNewPayment((prev) => ({
      ...prev,
      related_month: selectedMonthId,
    }));
  };


  const paymentSchema = yup.object().shape({
		related_landlord_rental: yup.number().required("Please select a property"),
		amount: yup.number().min(1, 'The amount must be greater than zero').required("Input the payment amount"),
		date_paid: yup.date().required("Select date for yhe payment"),
    related_month: yup.string().required("Please enter the month for the payement"),
		description: yup.string().required("This is required"),
		paid_by: yup.number().required("This is required"),
	});

  const editPaymentSchema = yup.object().shape({
		related_landlord_rental: yup.number().required("Please select a property"),
		amount: yup.number().min(1, 'The amount must be greater than zero').required("Input the payment amount"),
		date_paid: yup.date().required("Select date for yhe payment"),
    related_month: yup.string().required("Please enter the month for the payement"),
		description: yup.string().required("This is required"),
	});

  const handleAddPayment = async () => {

    try {
			await paymentSchema.validate(newPayment, { abortEarly: false });
		} catch (error) {
			const newError = {}
			error.inner.forEach(err => {
				newError[err.path]=err.message
			})
			setErrors(newError)
			return
		}


    setIsSubmittingData(true);
    axios
      .post(`${baseURL}/admin/landlord_payments`, newPayment)
      .then((response) => {
        console.log("Payment added successfully", response.data);
        setOpenSnackbar(true); // Show Snackbar when payment is successful
        handleCloseDialog();
      })
      .catch((error) => {
        console.error("There was an error adding the payment!", error);
      })
      .finally(() => {
        setIsSubmittingData(false);
        setNewItemData(true);
      });
  };

  const handleEditLandlordPayments = async () => {
     try {
       await editPaymentSchema.validate(selectedLandlordPayment, { abortEarly: false });
     } catch (error) {
       const newError = {};
       error.inner.forEach((err) => {
         newError[err.path] = err.message;
       });
       setErrors(newError);
       return;
     }

    try {
      const response = await axios.post(
        `${baseURL}/admin/landlord_payments/details/${selectedLandlordPayment.id}`,
        selectedLandlordPayment
      );
      console.log("Payment edited successfully:", response.data);

      setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
      setSeverity("success");
      setMessage("Payment edited successfully");
      setEditDialogOpen(false);
      setNewItemData();
      setAnchorEl(null)
    } catch (error) {
      console.error("Error editing payment:", error);

      setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
      setSeverity("error");
      setMessage("Error editing payment");
    }
  };

  const paymentData = [
    { id: 1, date: "2024-01-15", amount: 1200, method: "Credit Card" },
    { id: 2, date: "2024-02-15", amount: 1300, method: "Bank Transfer" },
    { id: 3, date: "2024-03-15", amount: 1400, method: "Credit Card" },
  ];

  const filteredPaymentData =
    filterMonth === "All Months"
      ? paymentData
      : paymentData.filter((payment) =>
          payment.date.startsWith(
            new Date(`${filterMonth} 1, 2024`).toISOString().slice(0, 7)
          )
        );


  return (
    <Box m="20px">
      <Box sx={{ p: 3 }}>
        <FlexBetween>
          <BasicCard
            name="Overall Total"
            value={withoutSymbolFormatter(parseFloat(landlordPayments.total_earned))}
          />
          <BasicCard
            name="Total Amount Received"
            value={withoutSymbolFormatter(
              parseFloat(landlordPayments.total)
            )}
          />

          <BasicCard
            name="Total Amount Due"
            value={withoutSymbolFormatter(
              parseFloat(landlordPayments.amount_due)
            )}
          />

          <Button
            variant="contained"
            color="primary"
            sx={{ height: 56, width: 200 }}
            onClick={handleOpenDialog}
          >
            Add Payment
          </Button>
        </FlexBetween>

        <Box sx={{ mt: 5 }}>
          <LandlordDetailsTable
            page={page}
            anchorEl={anchorEl}
            rowsPerPage={rowsPerPage}
            handleEditDialogOpen={handleEditDialogOpen}
            handleDeleteDialogOpen={handleDeleteDialogOpen}
            handleMenuClick={handleMenuClick}
            handleMenuClose={handleMenuClose}
            setSelectedLandlordPayment={setSelectedLandlordPayment}
            handleChangePage={handleChangePage}
            paymentData={filteredPaymentData}
            landlordPayments={landlordPayments}
            isLoadingLandlordPayments={isLoadingLandlordPayments}
            errorLandlordPayments={errorLandlordPayments}
            setNewItemData={setNewItemData}
          />
        </Box>
      </Box>

      <FormDialog
        open={openDialog}
        title="Add Payment"
        text="Fill out the details below to add a new payment."
        content={
          <AddPaymentForm
            newPayment={newPayment}
            handleInputChange={handleInputChange}
            handleMonthChange={handleMonthChange}
            setMonthsAndYears={setMonthsAndYears}
            errors={errors}
          />
        }
        handleClose={handleCloseDialog}
        handleSubmit={handleAddPayment}
        isSubmittingData={isSubmittingData}
      />

      {selectedLandlordPayment ? (
        <EditLandlorPayments
          open={editDialogOpen}
          title="Edit Landlord Payment Details"
          handleClose={handleEditDialogClose}
          errors={errors}
          handleSubmit={handleEditLandlordPayments}
          handleInputChange={handleEditInputChange}
          isSubmittingData={false}
          selectedLandlordPayment={selectedLandlordPayment}
          // landlord_name={`${selectedLandlordPayment?.related_landlord_rental.related_landlord.related_user.first_name} ${selectedLandlordPayment?.related_landlord_rental.related_landlord.related_user.last_name}`}
          // description={selectedLandlordPayment?.description}
          // business_name={
          //   selectedLandlordPayment?.related_landlord_rental.related_landlord
          //     .business_name
          // }
          // rental_name={
          //   selectedLandlordPayment?.related_landlord_rental.rental_name
          // }
          // date_paid={selectedLandlordPayment?.date_paid || ""}
          // month_year={`${selectedLandlordPayment?.related_month?.month || ""} ${
          //   selectedLandlordPayment?.related_month?.year || ""
          // }`}
          // amount={selectedLandlordPayment?.amount || ""}
          // paid_by={`${selectedLandlordPayment?.added_by.first_name} ${selectedLandlordPayment?.added_by.last_name}`}
        />
      ) : null}

      <FormDialog
        open={deleteDialogOpen}
        title="Delete Payment Record"
        enableActions={true}
        content="Are you sure you want to delete this payment?"
        handleClose={handleDeleteDialogClose}
        handleSubmit={handleDeletePayment}
        buttonText="Delete Payment"
        isSubmittingData={isSubmittingData}
      />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Payment added successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LandlordPaymentDetails;
