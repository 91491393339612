import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";  // Import the icon
import {Box, Button, TablePagination, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import UnitsPopover from "components/PopOvers/UnitsPopover"; // Import UnitsPopover
import { useNavigate } from "react-router-dom";
import { useRentalUnits } from "services/hooks/rentals";
import { useSelector } from "react-redux";
import FlexBetween from "components/FlexBetween";
import FormDialog from "components/ManagerModals/ViewModal";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { StyledTableCell, StyledTableRow } from "./Styles/TableAndCellStyles";
import axios from "axios";
import { baseURL } from "services/API";
import TopSnack from "components/TopSnack";

export default function UnitsTable({currentPage=1, itemsPerPage=10}) {
  const id = useSelector((state) => state.property_id);
  const theme = useTheme();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null); // For the popover
  const [activeUnit, setActiveUnit] = React.useState(null); // Store active unit for popover
  const openPopper = Boolean(anchorEl);
  const popperId = openPopper ? "simple-popover" : undefined;
  const [modal, setModal] = React.useState("");
	const [open, setOpen] = React.useState(false);
  const [deleteMessage, setDeleteMessage] = React.useState("");
  const [deleteButton, setDeleteButton] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState({
      open: false,
      vertical: "top",
      horizontal: "center",
    });
    const [severity, setSeverity] = React.useState("");
    const [message, setMessage] = React.useState("");

  const navigate = useNavigate();
  const { rentalUnits, isLoadingRentalUnits, error, setNewData } = useRentalUnits(id);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack({ ...openSnack, open: false });
  };

  const handleClickPopOver = (event, unit) => {
    setAnchorEl(event.currentTarget);
    setActiveUnit(unit); // Set the active unit for the popover
  };

  const handleClosePopOver = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
		setModal("");
  };

  const handleModalView = (modalView) => {
		setModal(modalView);
	};

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSubmitDelete = async () => {
    try {
      const response = await axios.delete(
        `${baseURL}/landlords/rentals/units/delete?unit_id=${activeUnit.id}`
      );

      setOpenSnack({ ...openSnack, open: true });
      setSeverity("success");
      setMessage("Unit Deleted Successfully");
      setNewData(true);
    } catch (err) {
      setOpenSnack({ ...openSnack, open: true });
      setSeverity("error");
      setMessage("Unit Delete failed, Please check unit details");
    }
  };

  function TablePaginationActions(props) {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  function toSentenceCase(str) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <Box sx={{ flexGrow: 1, padding: "2rem" }}>
          <FlexBetween sx={{ marginBottom:"5px" }}>
            <Typography variant="h4" gutterBottom>Rental Units</Typography>
            <Button variant="contained" color="primary" onClick={()=>{navigate("/unitCreate");}}>
              Add Unit
            </Button>
          </FlexBetween>
          
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Unit Name</StyledTableCell>
                  <StyledTableCell align="left">Unit Type</StyledTableCell>
                  <StyledTableCell align="left">Unit Size(m2)</StyledTableCell>
                  <StyledTableCell align="left">Rent Currency</StyledTableCell>
                  <StyledTableCell align="left">Unit Rent</StyledTableCell>
                  <StyledTableCell align="left">Unit Rent Cycle</StyledTableCell>
                  <StyledTableCell align="left">Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoadingRentalUnits ? (
                  <StyledTableRow>
                    {/* Loading Spinner or Empty Row */}
                  </StyledTableRow>
                ) : (
                  <>
                    {rentalUnits.length > 0
                      ? rentalUnits.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell component="th" scope="row">
                          {row.related_rental_unit.unit_name}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {toSentenceCase(row.related_rental_unit.unit_type)}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.related_rental_unit.unit_size}
                        </StyledTableCell>
                        <StyledTableCell align="left">{row.rent_currency}</StyledTableCell>
                        <StyledTableCell align="left">{row.unit_rent}</StyledTableCell>
                        <StyledTableCell align="left">
                          {toSentenceCase(row.unit_rent_cycle)}
                        </StyledTableCell>
                        <StyledTableCell>
                          <IconButton
                            color="primary"
                            size="small"
                            aria-describedby={popperId}
                            onClick={(event) => handleClickPopOver(event, row)}
                          >
                            <MoreHorizIcon />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    )) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={7}>
                        No data Available
                      </StyledTableCell>
                    </StyledTableRow>
                    )}
                </> 
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    count={rentalUnits.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          {/* UnitsPopover for handling actions */}
          <UnitsPopover
            popperId={popperId}
            openPopper={openPopper}
            anchorEl={anchorEl}
            handleClosePopOver={handleClosePopOver}
            activeUnit={activeUnit}
            handleModalView={handleModalView}
            handleClickOpen={handleClickOpen}
            navigate={navigate}
            setDeleteButton={setDeleteButton}
            setDeleteMessage={setDeleteMessage}
          />
        </Box>
      </Box>
      {modal === "delete" ? (
        <FormDialog
          title="Delete Unit"
          enableActions={deleteButton}
          open={open}
          content={deleteMessage}
          handleSubmit={handleSubmitDelete}
          handleClose={handleClose}
          buttonText="Delete Unit"
        />
      ) : null}
      <TopSnack
        vertical={openSnack.vertical}
        horizontal={openSnack.horizontal}
        open={openSnack.open}
        handleCloseSnack={handleCloseSnack}
        message={message}
        severity={severity}
      />
    </Box>
  );
}
