import * as React from "react";
import axios from "axios";
import { baseURL } from "../API";
import { useSelector } from "react-redux";
import { useState, useEffect } from 'react';

// Custom hooks

const useRentals = () => {
	const token = useSelector((state) => state.token);
	const landlordId = useSelector((state) => state.user.landlord_id);

	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [rentals, setRentals] = React.useState([]);
	const [isLoadingRentals, setLoadingRentals] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newData, setNewData] = React.useState(true);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/rentals`);
				setRentals(response.data.data);
				setLoadingRentals(false);
				setNewData(false);
			} catch (e) {
				setError(true);
				setLoadingRentals(false);
			}
		};
		fetchData();
	}, [landlordId, token, newData]);

	return { rentals, isLoadingRentals, error, setNewData };
};

const useRentalUnits = (activeRental) => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [rentalUnits, setRentalUnits] = React.useState("");
	const [isLoadingRentalUnits, setLoadingRentalUnits] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newData, setNewData] = React.useState(true);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/rentals/units?rental_id=${activeRental}`);
				setRentalUnits(response.data.data);
				setLoadingRentalUnits(false);
				setNewData(false);
			} catch (e) {
				setError(true);
				setLoadingRentalUnits(false);
			}
		};
		fetchData();
	}, [activeRental, token, newData]);

	return { rentalUnits, isLoadingRentalUnits, error, setNewData };
};


const useUnassignedRentalUnits = (activeUnassignedRental) => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [unassignedRentalUnits, setUnassignedRentalUnits] = React.useState("");
	const [isLoadingUnassignedRentalUnits, setLoadingUnassignedRentalUnits] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newData , setNewData] = React.useState(true);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/rentals/units/unoccupied?rental_id=${activeUnassignedRental}`);
				setUnassignedRentalUnits(response.data.data);
				setLoadingUnassignedRentalUnits(false);
				setNewData(false);
			} catch (e) {
				setError(true);
				setLoadingUnassignedRentalUnits(false);
			}
		};
		fetchData();
	}, [activeUnassignedRental, token, newData]);

	return { unassignedRentalUnits, isLoadingUnassignedRentalUnits, error, setNewData  };
};

const useHandleRentalFormSubmit = async (rentalName, rentalType, rentalLocation) => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const landlordId = useSelector((state) => state.user.id);

	try {
		await axios.post(`${baseURL}/landlords/rentals`, {
			related_landlord: landlordId,
			rental_name: rentalName,
			rental_type: rentalType,
			location: rentalLocation,
		});
		// Handle success
	} catch (err) {
		console.error(err);
		// Handle error
	}
};

const useGetRentalSchedules = () => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [rentalSchedules, setRentalSchedules] = React.useState([]);
	const [isLoadingRentalSchedules, setLoadingRentalSchedules] = React.useState(true);
	const [errorLoadingRentalSchedules, setErrorLoadingRentalSchedules] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/tenants/schedules/admin`);
				setRentalSchedules(response.data.data);
				setLoadingRentalSchedules(false);
			} catch (e) {
				setErrorLoadingRentalSchedules(true);
				setLoadingRentalSchedules(false);
			}
		};
		fetchData();
	}, [token]);

	return { rentalSchedules, isLoadingRentalSchedules, errorLoadingRentalSchedules };
};

const useGetRentalTenants = () => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [rentalTenants, setRentalTenants] = React.useState([]);
	const [isLoadingRentalTenants, setLoadingRentalTenants] = React.useState(true);
	const [errorLoadingRentalTenants, setErrorLoadingRentalTenants] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/accounts/tenants`);
				setRentalTenants(response.data.data);
				setLoadingRentalTenants(false);
			} catch (e) {
				setErrorLoadingRentalTenants(true);
				setLoadingRentalTenants(false);
			}
		};
		fetchData();
	}, [token]);

	return { rentalTenants, isLoadingRentalTenants, errorLoadingRentalTenants };
};

const useGetTenantsByRentalProperty = (rental_id) => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [tenantsByProperty, setTenantsByProperty] = React.useState([]);
	const [isLoadingTenantsByProperty, setLoadingTenantsByProperty] = React.useState(true);
	const [errorLoadingTenantsByProperty, setErrorLoadingTenantsByProperty] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/rentals/tenants?rental_id=${rental_id}`);
				setTenantsByProperty(response.data.data);
				setLoadingTenantsByProperty(false);
			} catch (e) {
				setErrorLoadingTenantsByProperty(true);
				setLoadingTenantsByProperty(false);
			}
		};
		fetchData();
	}, [token]);

	return { tenantsByProperty, isLoadingTenantsByProperty, errorLoadingTenantsByProperty };
};

const useGetDashboardData = () => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const landlordId = useSelector((state) => state.user.id);
	const [dashboardData, setDashboardData] = React.useState({});
	const [isLoadingDashboardData, setLoadingDashboardData] = React.useState(true);
	const [errorLoadingDashboardData, setErrorLoadingDashboardData] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/admin/dashboard`);
				setDashboardData(response.data.data);
				setLoadingDashboardData(false);
			} catch (e) {
				setErrorLoadingDashboardData(true);
				setLoadingDashboardData(false);
			}
		};
		fetchData();
	}, [landlordId, token]);

	return { dashboardData, isLoadingDashboardData, errorLoadingDashboardData };
};

const useGetPaymentsData = () => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const landlordId = useSelector((state) => state.user.id);
	const [paymentsData, setPaymentsData] = React.useState([]);
	const [isLoadingPaymentsData, setLoadingPaymentsData] = React.useState(true);
	const [errorLoadingPaymentsData, setErrorLoadingPaymentsData] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/tenants/payments/admin`);
				setPaymentsData(response.data.data);
				setLoadingPaymentsData(false);
			} catch (e) {
				setErrorLoadingPaymentsData(true);
				setLoadingPaymentsData(false);
			}
		};
		fetchData();
	}, [landlordId, token]);

	return { paymentsData, isLoadingPaymentsData, errorLoadingPaymentsData };
};

const useLandlords = () => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [landlords, setLandlords] = React.useState([]);
	const [isLoadingLandlords, setLoadingLandlords] = React.useState(true);
	const [error, setError] = React.useState(false);
	const [newItemData, setNewItemData] = useState(true);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/admin/landlords`);
				setLandlords(response.data.data);
				setLoadingLandlords(false);
				setNewItemData(false)
			} catch (e) {
				setError(true);
				setLoadingLandlords(false);
			}
		};
		fetchData();
	}, [token, newItemData]);

	return { landlords, isLoadingLandlords, error, setNewItemData };
};

const useInvisibleLandlords = () => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [invisibleLandlords, setInvisibleLandlords] = React.useState([]);
	const [isLoadingInvisibleLandlords, setLoadingInvisibleLandlords] = React.useState(true);
	const [errorInvisibleLandlords, setErrorInvisibleLandlords] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/admin/landlords?visibility=False`);
				setInvisibleLandlords(response.data.data);
				setLoadingInvisibleLandlords(false);
			} catch (e) {
				setErrorInvisibleLandlords(true);
				setLoadingInvisibleLandlords(false);
			}
		};
		fetchData();
	}, [token]);

	return { invisibleLandlords, isLoadingInvisibleLandlords, errorInvisibleLandlords };
};

const useBrokers = () => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [brokers, setBrokers] = React.useState([]);
	const [isLoadingBrokers, setLoadingBrokers] = React.useState(true);
	const [error, setError] = React.useState(false);

	const fetchBrokers = async () => {
		try {
			const response = await axios.get(`${baseURL}/landlords/admin/brokers`);
			setBrokers(response.data.data);
			setLoadingBrokers(false);
		} catch (e) {
			console.error("Error fetching brokers:", e);
			setError(true);
			setLoadingBrokers(false);
		}
	};

	React.useEffect(() => {
		fetchBrokers();
	}, [token]);

	return { brokers, isLoadingBrokers, error, refetch: fetchBrokers };
};


const useAttachLandlord = () => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const attachLandlord = async (brokerId, landlordId) => {
		setLoading(true);
		try {
			const response = await axios.post(
				`${baseURL}/landlords/admin/brokers/landlords`,
				{
					related_broker: brokerId,
					related_landlord: landlordId,
				}
			);
			setLoading(false);
			return response.data;
		} catch (error) {
			setLoading(false);
			setError(error);
			throw error;
		}
	};

	return { attachLandlord, loading, error };
};



const useBrokerLandlords = (brokerId) => {
	const [brokerLandlords, setBrokerLandlords] = useState([]);
	const [loadingBrokerLandlords, setLoadingBrokerLandlords] = useState(true);
	const [errorBrokerLandlords, setErrorBrokerLandlords] = useState(null);
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

	useEffect(() => {
		const fetchLandlords = async () => {
			try {
				const response = await axios.get(
					`${baseURL}/landlords/admin/brokers/landlords?broker_id=${brokerId}`,
				);
				setBrokerLandlords(response.data.data);
				setLoadingBrokerLandlords(false)
			} catch (error) {
				setErrorBrokerLandlords(error);
				setLoadingBrokerLandlords(false)
			} finally {
				setLoadingBrokerLandlords(false);
			}
		};

		fetchLandlords();
	}, [brokerId]);

	return { brokerLandlords, loadingBrokerLandlords, errorBrokerLandlords };
};

const useTenants = (landlordId) => {
	const [tenants, setTenants] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

	useEffect(() => {
		const fetchTenants = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/rentals/tenants?landlord_id=${landlordId}`);
				setTenants(response.data.data);
			} catch (err) {
				setError(err);
			} finally {
				setLoading(false);
			}
		};

		fetchTenants();
	}, []);

	return { tenants, loading, error };
};


const useLandlordProperties = (landlordId) => {
	const [landlordProperties, setLandlordProperties] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [newData, setNewData] = useState(true);
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

	useEffect(() => {
		const fetchLandlordProperties = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/rentals?landlord_id=${landlordId}`);
				setLandlordProperties(response.data.data);
				setNewData(false)
			} catch (err) {
				setError(err);
			} finally {
				setLoading(false);
			}
		};

		fetchLandlordProperties();
	}, [newData]);

	return { landlordProperties, loading, error, setNewData };
};

export const useUsers = () => {
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

	useEffect(() => {
		const fetchUsers = async () => {
			try {
				const response = await axios.get(`${baseURL}/accounts/users/tenant_users?user_filter=0`);

				setUsers(response.data.data);
			} catch (err) {
				setError(err);
			} finally {
				setLoading(false);
			}
		};

		fetchUsers();
	}, []);

	return { users, loading, error };
};
const useManagers = () => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [managers, setManagers] = useState([]);
	const [isLoadingManager, setLoadingManager] = useState(true);
	const [error, setError] = useState(false);

	const fetchManager = async () => {
		try {
			const response = await axios.get(`${baseURL}/admin/managers`);
			setManagers(response.data.data);
			setLoadingManager(false);
		} catch (e) {
			console.error("Error fetching managers:", e);
			setError(true);
			setLoadingManager(false);
		}
	};

	React.useEffect(() => {
		fetchManager();
	}, [token]);

	return { managers, isLoadingManager, error, refetch: fetchManager };
};
const useAttachedProperty = (managerId) => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [attachedProperties, setAttachedProperty] = useState([]);
	const [isLoadingAttachedProperties, setLoadingAttachedProperties] = useState(true);
	const [errorAttachedProperties, setError] = useState(false);

	const fetchAttachedProperty = async () => {
		try {
			const response = await axios.get(`${baseURL}/admin/managers/properties?manager_id=${managerId}`);
			setAttachedProperty(response.data.data);
			setLoadingAttachedProperties(false);
		} catch (e) {
			console.error("Error fetching managers:", e);
			setError(true);
			setLoadingAttachedProperties(false);
		}
	};

	React.useEffect(() => {
		fetchAttachedProperty();
	}, []);

	return { attachedProperties, isLoadingAttachedProperties, errorAttachedProperties, refetch: fetchAttachedProperty };
};
const useUnAssignedProperties = () => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

	const [unAssignedProperties, setUnAssignedProperties] = useState([]);
	const [isLoadingUnAssignedProperties, setLoadingUnAssignedProperties] = useState(true);
	const [errorUnAssignedProperties, setError] = useState(false);

	const fetchUnAssignedProperty = async () => {
		try {
			const response = await axios.get(`${baseURL}/admin/unassigned_properties`);
			setUnAssignedProperties(response.data.data);
			setLoadingUnAssignedProperties(false);
		} catch (e) {
			console.error("Error fetching unassigned properties:", e);
			setError(true);
			setLoadingUnAssignedProperties(false);
		}
	};

	useEffect(() => {
		fetchUnAssignedProperty();
	}, []);

	return { unAssignedProperties, isLoadingUnAssignedProperties, errorUnAssignedProperties, refetch: fetchUnAssignedProperty };
};


const useExpenseTypes = () => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [expenseTypes, setExpenseTypes] = React.useState([]);
	const [isLoadingExpenseTypes, setLoadingExpenseTypes] = React.useState(true);
	const [errorExpenseTypes, setError] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/admin/expense_types`);
				setExpenseTypes(response.data.data);
				setLoadingExpenseTypes(false);
			} catch (e) {
				setError(true);
				setLoadingExpenseTypes(false);
			}
		};
		fetchData();
	}, []);

	return { expenseTypes, isLoadingExpenseTypes, errorExpenseTypes };
};

const useLandlordExpenses = (landlord_id) => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [landlordExpenses, setLandlordExpenses] = React.useState({expenses:[], total:0});
	const [isLoadingLandlordExpenses, setLoadingLandlordExpenses] = React.useState(true);
	const [errorLandlordExpenses, setError] = React.useState(false);
	const [newData, setNewData] = React.useState(true);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/admin/landlord_expenses?landlord_id=${landlord_id}`);
				setLandlordExpenses(response.data.data);
				setLoadingLandlordExpenses(false);
				setNewData(false);
			} catch (e) {
				setError(true);
				setLoadingLandlordExpenses(false);
			}
		};
		fetchData();
	}, [token, newData]);

	return { landlordExpenses, isLoadingLandlordExpenses, errorLandlordExpenses, setNewData };
};

const useBrokerPayments = (brokerId) => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [brokerPayments, setBrokerPayments] = React.useState([]);
	const [isLoadingBrokerPayments, setLoadingBrokerPayments] = React.useState(true);
	const [errorBrokerPayments, setError] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/admin/broker_payments?broker_id=${brokerId}`);
				setBrokerPayments(response.data.data);
				setLoadingBrokerPayments(false);
			} catch (e) {
				setError(true);
				setLoadingBrokerPayments(false);
			}
		};
		fetchData();
	}, []);

	return { brokerPayments, isLoadingBrokerPayments, errorBrokerPayments };
};
const useMonthsAndYears = (brokerId) => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [monthsAndYears, setMonthsAndYears] = React.useState([]);
	const [isLoadingMonthsAndYears, setLoadingMonthsAndYears] = React.useState(true);
	const [errorMonthsAndYears, setErrorMonthsAndYears] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/admin/months`);
				setMonthsAndYears(response.data.data);
				setLoadingMonthsAndYears(false);
			} catch (e) {
				setErrorMonthsAndYears(true);
				setLoadingMonthsAndYears(false);
			}
		};
		fetchData();
	}, []);

	return { monthsAndYears, isLoadingMonthsAndYears, errorMonthsAndYears };
};

const useLandlordPayments = (landlordId) => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [landlordPayments, setLandlordPayments] = React.useState([]);
	const [isLoadingLandlordPayments, setLoadingLandlordPayments] = React.useState(true);
	const [errorLandlordPayments, setError] = React.useState(false);
	const [newItemData, setNewItemData] = useState(true);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/admin/landlord_payments?landlord_id=${landlordId}`);
				setLandlordPayments(response.data.data);
				setLoadingLandlordPayments(false);
				setNewItemData(false)
			} catch (e) {
				setError(true);
				setLoadingLandlordPayments(false);
			}
		};
		fetchData();
	}, [newItemData]);

	return { landlordPayments, isLoadingLandlordPayments, errorLandlordPayments, setNewItemData };
};

const useTenantPayments = (occupancyId) => {
	const token = useSelector((state) => state.token);
	const refreshCount = useSelector((state) => state.refreshCount);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [tenantPayments, setTenantPayments] = React.useState([]);
	const [isLoadingTenantPayments, setLoadingTenantPayments] = React.useState(true);
	const [errorTenantPayments, setErrorTenantPayments] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/tenants/payments/active_periods?occupancy_id=${occupancyId}`);
				setTenantPayments(response.data.data);
				setLoadingTenantPayments(false);
			} catch (e) {
				setErrorTenantPayments(true);
				setLoadingTenantPayments(false);
			}
		};
		fetchData();
	}, [refreshCount, occupancyId]);

	return { tenantPayments, isLoadingTenantPayments, errorTenantPayments };
};

const useGetTenantOccupancyActivePeriodsData = occupancyId => {
	const token = useSelector((state) => state.token)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [activePeriods, setActivePeriods] = React.useState([]);
	const refreshCount = useSelector((state) => state.refreshCount);
	const [isLoadingActivePeriods, setLoadingActivePeriods] = React.useState(true);
	const [errorLoadingActivePeriods, setErrorLoadingActivePeriods] = React.useState(false);
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/tenants/completed_payments?occupancy_id=${occupancyId}`);
				setActivePeriods(response.data.data);
				setLoadingActivePeriods(false);
			} catch (e) {
				setErrorLoadingActivePeriods(true);
				setLoadingActivePeriods(false);
			}
		};
		fetchData();
	}, [occupancyId, refreshCount]);
	return { activePeriods, isLoadingActivePeriods, errorLoadingActivePeriods };
};


const useGetPaymentsByActivePeriodData = (period_id) => {
	const token = useSelector((state) => state.token)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [paymentsByActivePeriodData, setPaymentsByActivePeriodData] = React.useState([]);
	const [isLoadingPaymentsByActivePeriodData, setLoadingPaymentsByActivePeriodData] = React.useState(true);
	const [errorLoadingPaymentsByActivePeriodData, setErrorLoadingPaymentsByActivePeriodData] = React.useState(false);
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/tenants/completed_payments/payments_list?period_id=${period_id}`);
				setPaymentsByActivePeriodData(response.data.data);
				setLoadingPaymentsByActivePeriodData(false);
			} catch (e) {
				setErrorLoadingPaymentsByActivePeriodData(true);
				setLoadingPaymentsByActivePeriodData(false);
			}
		};
		fetchData();
	}, [period_id]);
	return { paymentsByActivePeriodData, isLoadingPaymentsByActivePeriodData, errorLoadingPaymentsByActivePeriodData };
};

const useReferredLandlords = () => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [referredLandlords, setReferredLandlords] = React.useState([]);
	const [isLoadingReferredLandlords, setLoadingReferredLandlords] = React.useState(true);
	const [errorReferredLandlords, setError] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/admin/referrals/landlord/list`);
				setReferredLandlords(response.data.data);
			} catch (e) {
				setError(true);
				setLoadingReferredLandlords(false);
			}
		};
		fetchData();
	}, []);

	return { referredLandlords, isLoadingReferredLandlords, errorReferredLandlords };
};

const useRegisteredProperties = () => {
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [registeredProperties, setRegisteredProperties] = React.useState([]);
	const [isLoadingRegisteredProperties, setLoadingRegisteredProperties] = React.useState(true);
	const [errorRegisteredProperties, setError] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/admin/landlord_registration/list`);
				setRegisteredProperties(response.data.data);
			} catch (e) {
				setError(true);
				setLoadingRegisteredProperties(false);
			}
		};
		fetchData();
	}, []);

	return { registeredProperties, isLoadingRegisteredProperties, errorRegisteredProperties };
};

const useGetRentalTicketsByProperty = () => {
	const token = useSelector((state) => state.token)
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const rentalId = useSelector((state) => state.property_id)
	const [rentalTicketsByProperty, setRentalTicketsByProperty] = React.useState([]);
	const [isLoadingRentalTicketsByProperty, setLoadingRentalTicketsByProperty] = React.useState(true);
	const [errorLoadingRentalTicketsByProperty, setErrorLoadingRentalTicketsByProperty] = React.useState(false);
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/rentals/units/tickets?rental_id=${rentalId}`);
				setRentalTicketsByProperty(response.data.data);
				setLoadingRentalTicketsByProperty(false);
			} catch (e) {
				setErrorLoadingRentalTicketsByProperty(true);
				setLoadingRentalTicketsByProperty(false);
			}
		};
		fetchData();
	}, [rentalId]);
	return { rentalTicketsByProperty, isLoadingRentalTicketsByProperty, errorLoadingRentalTicketsByProperty };
};

const usePropertyDashboardStatistics = (property) =>{
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [propertyDashboardStatistics, setPropertyDashboardStatistics] = React.useState({"collection_rate": {"paid": 0,"unpaid": 0},"occupancy_rate": {"occupied": 0,"unoccupied": 0}});
	const [isLoadingPropertyDashboardStatistics, setLoadingPropertyDashboardStatistics] = React.useState(true);
	const [errorPropertyDashboardStatistics, setError] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/properties/statistics?rental_id=${property}`);
				setPropertyDashboardStatistics(response.data.data);
				setLoadingPropertyDashboardStatistics(false);
			} catch (e) {
				setError(true);
				setLoadingPropertyDashboardStatistics(false);
			}
		};
		fetchData();
	}, [property]);

	return { propertyDashboardStatistics, isLoadingPropertyDashboardStatistics, errorPropertyDashboardStatistics };
}

const usePropertyDashboardRevenue = (property) =>{
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [propertyDashboardRevenue, setPropertyDashboardRevenue] = React.useState({
        "balances": {"total_balance": 0,"total_income": 0,"total_expense": 0},
        "months": [
            {"month": "January","alias": "Jan","income": 0,"expense": 0},{"month": "February","alias": "Feb","income": 0,"expense": 0},{"month": "March","alias": "Mar","income": 0,"expense": 0},{"month": "April","alias": "Apr","income": 0,"expense": 0},{"month": "May","alias": "May","income": 0,"expense": 0},{"month": "June","alias": "Jun","income": 0,"expense": 0},{"month": "July","alias": "Jul","income": 0,"expense": 0},{"month": "August","alias": "Aug","income": 0,"expense": 0},{"month": "September","alias": "Sep","income": 0,"expense": 0},{"month": "October","alias": "Oct","income": 0,"expense": 50000},{"month": "November","alias": "Nov","income": 0,"expense": 0},{"month": "December","alias": "Dec","income": 0,"expense": 0}
        ]
    });
	const [isLoadingPropertyDashboardRevenue, setLoadingPropertyDashboardRevenue] = React.useState(true);
	const [errorPropertyDashboardRevenue, setError] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/properties/revenue_overview?rental_id=${property}`);
				setPropertyDashboardRevenue(response.data.data);
				setLoadingPropertyDashboardRevenue(false);
			} catch (e) {
				setError(true);
				setLoadingPropertyDashboardRevenue(false);
			}
		};
		fetchData();
	}, [property]);

	return { propertyDashboardRevenue, isLoadingPropertyDashboardRevenue, errorPropertyDashboardRevenue };
}

const usePropertyDashboardRevenueMonths = (property) =>{
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [propertyDashboardRevenueMonths, setPropertyDashboardRevenueMonths] = React.useState({
        "balances": {"total_balance": 0,"total_income": 0,"total_expense": 0},
        "months": [
            {"month": "January","alias": "Jan","income": 0,"expense": 0},{"month": "February","alias": "Feb","income": 0,"expense": 0},{"month": "March","alias": "Mar","income": 0,"expense": 0},{"month": "April","alias": "Apr","income": 0,"expense": 0},{"month": "May","alias": "May","income": 0,"expense": 0},{"month": "June","alias": "Jun","income": 0,"expense": 0},{"month": "July","alias": "Jul","income": 0,"expense": 0},{"month": "August","alias": "Aug","income": 0,"expense": 0},{"month": "September","alias": "Sep","income": 0,"expense": 0},{"month": "October","alias": "Oct","income": 0,"expense": 50000},{"month": "November","alias": "Nov","income": 0,"expense": 0},{"month": "December","alias": "Dec","income": 0,"expense": 0}
        ]
    });
	const [isLoadingPropertyDashboardRevenueMonths, setLoadingPropertyDashboardRevenueMonths] = React.useState(true);
	const [errorPropertyDashboardRevenueMonths, setError] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/properties/monthly_income?rental_id=${property}`);
				setPropertyDashboardRevenueMonths(response.data.data);
				setLoadingPropertyDashboardRevenueMonths(false);
			} catch (e) {
				setError(true);
				setLoadingPropertyDashboardRevenueMonths(false);
			}
		};
		fetchData();
	}, [property]);

	return { propertyDashboardRevenueMonths, isLoadingPropertyDashboardRevenueMonths, errorPropertyDashboardRevenueMonths };
}


const usePropertyDashboardCards = (property) =>{
	const token = useSelector((state) => state.token);
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	const [propertyDashboardCards, setPropertyDashboardCards] = React.useState({
        "cards": {
            "total_units": {
                "number": 0
            },
            "open_tickets": {
                "number": 0
            },
            "current_tenants": {
                "number": 0,
                "units": 0
            },
            "rent_arrears": {
                "amount": 0,
                "units": 0,
                "as_of": "December"
            },
            "rent_collected": {
                "amount": 0,
                "units": 0,
                "total": 0
            }
        }
    });
	const [isLoadingPropertyDashboardCards, setLoadingPropertyDashboardCards] = React.useState(true);
	const [errorPropertyDashboardCards, setError] = React.useState(false);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${baseURL}/landlords/properties/dashboard_cards?rental_id=${property}`);
				setPropertyDashboardCards(response.data.data);
				setLoadingPropertyDashboardCards(false);
			} catch (e) {
				setError(true);
				setLoadingPropertyDashboardCards(false);
			}
		};
		fetchData();
	}, [property]);

	return { propertyDashboardCards, isLoadingPropertyDashboardCards, errorPropertyDashboardCards };
}

// Export hooks
export {
	useRentals, useHandleRentalFormSubmit, useRentalUnits,usePropertyDashboardRevenueMonths, useGetRentalSchedules, useGetRentalTenants, useGetDashboardData, useGetPaymentsData,
	useLandlords, useBrokers, useAttachLandlord, useBrokerLandlords, useTenants, useManagers, useAttachedProperty, useUnAssignedProperties, useGetTenantOccupancyActivePeriodsData,
	useLandlordExpenses, useExpenseTypes, useBrokerPayments, useMonthsAndYears, useLandlordPayments, useGetTenantsByRentalProperty, useReferredLandlords, useTenantPayments, useRegisteredProperties, useLandlordProperties, useUnassignedRentalUnits, useInvisibleLandlords, usePropertyDashboardStatistics, usePropertyDashboardCards, usePropertyDashboardRevenue, useGetRentalTicketsByProperty, useGetPaymentsByActivePeriodData
};


