import React from "react";
import {Box,Grid,MenuItem,TextField,CircularProgress, FormControlLabel, Checkbox} from "@mui/material";


const EditTenantPaymentForm = ({
  monthOptions,
  isLoadingMonthsAndYears,
  handleInputChange,
  errors,
  paymentData,
}) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {/* Row 2 */}
        <Grid item xs={6}>
          <TextField
            label="Amount Paid"
            type="number"
            name="amount"
            value={paymentData.amount}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            error={Boolean(errors.amount)}
            helperText={errors.amount}
          />
        </Grid>

        <Grid item xs={6}>
          {isLoadingMonthsAndYears ? (
            <CircularProgress size={24} sx={{ ml: 2 }} />
          ) : (
            <TextField
              labelId="related-month-label"
              name="related_month"
              select
              fullWidth
              error={Boolean(errors.related_month)}
              helperText={errors.related_month}
              value={paymentData.related_month}
              label="Month Paid For"
              onChange={handleInputChange}
            >
              {monthOptions.map((value, index) => {
                return (
                  <MenuItem key={index} value={value.id}>
                    {value.month} - {value.year}
                  </MenuItem>
                );
              })}
            </TextField>
          )}
        </Grid>

        {/* Row 3 */}
        <Grid item xs={6}>
          <TextField
            label="Payment Method"
            name="payment_method"
            select
            fullWidth
            error={Boolean(errors.payment_method)}
            helperText={errors.payment_method}
            value={paymentData.payment_method}
            onChange={handleInputChange}
          >
            <MenuItem value={1}>Cash</MenuItem>
            <MenuItem value={2}>Mobile Money</MenuItem>
            <MenuItem value={3}>Momo Pay</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Date Paid"
            type="date"
            name="date_paid"
            value={paymentData.date_paid}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            error={Boolean(errors.date_paid)}
            helperText={errors.date_paid}
          />
        </Grid>

        <Grid item xs={6}>
          <FormControlLabel
            label="Is this an initial Payment?"
            control={
              <Checkbox
                checked={paymentData.is_initial_payment}
                onChange={handleInputChange}
              />
            }
          />
        </Grid>

        {paymentData.is_initial_payment && (
          <Grid item xs={12}>
            <FormControlLabel
              label="Does this initial payment have a security deposit?"
              control={
                <Checkbox
                  checked={paymentData.has_security_deposit}
                  onChange={handleInputChange}
                />
              }
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default EditTenantPaymentForm;
