import  React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { FormControl, Grid, InputLabel, Select, Typography, MenuItem, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { useMonthsAndYears, useManagers, useLandlordProperties } from "services/hooks/rentals";

export default function EditLandlorPayments({open,title,text,handleClose,handleSubmit,isSubmittingData, errors, selectedLandlordPayment,handleInputChange}) {
    const landlord = useSelector((state) => state.landloard); // Select the landlord stored in the global state
    const {landlordProperties,loading, error} = useLandlordProperties(landlord.id);
    const { monthsAndYears, isLoadingMonthsAndYears, errorMonthsAndYears } = useMonthsAndYears();
    const { managers, isLoadingManagers, error: errorManagers } = useManagers();



  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ fontSize: 20 }}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: 15, marginBottom: 2 }}>
            {text}
          </DialogContentText>

          {/* Form Fields */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              select
              name="related_landlord_rental"
              value={selectedLandlordPayment.related_landlord_rental}
              onChange={handleInputChange}
              label="Landlord Rental"
              helperText={errors.related_landlord_rental}
              error={Boolean(errors.related_landlord_rental)}
            >
              {loading ? (
                <MenuItem disabled><CircularProgress size={24} /></MenuItem>
              ) : error ? (
                <MenuItem disabled><Typography color="error">  Failed to load properties</Typography></MenuItem>
              ) : landlordProperties.length === 0 ? (
                <MenuItem disabled><Typography>No properties available</Typography></MenuItem>
              ) : ( landlordProperties.map((rental) => (
                <MenuItem key={rental.id} value={rental.id} selected={landlordProperties.id === selectedLandlordPayment.related_landlord_rental.id}>{rental.rental_name}</MenuItem>
                ))
              )}
            </TextField>

            <TextField
              label="Amount"
              type="number"
              name="amount"
              value={selectedLandlordPayment.amount}
              onChange={handleInputChange}
              variant="outlined"
              helperText={errors.amount}
              error={Boolean(errors.amount)}
            />
            <TextField
              label="Description"
              name="description"
              value={selectedLandlordPayment.description}
              onChange={handleInputChange}
              variant="outlined"
              helperText={errors.description}
              error={Boolean(errors.description)}
            />
            <TextField
              label="Date Paid"
              type="date"
              name="date_paid"
              value={selectedLandlordPayment.date_paid}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              helperText={errors.date_paid}
              error={Boolean(errors.date_paid)}
            />

            <TextField
              select
              name="related_month"
              value={selectedLandlordPayment.related_month}
              onChange={handleInputChange}
              label="Related Month (Month and Year)"
              helperText={errors.related_month}
              error={Boolean(errors.related_month)}
            >
              {isLoadingMonthsAndYears ? (
                <MenuItem disabled>
                  <CircularProgress size={24} />
                </MenuItem>
              ) : errorMonthsAndYears ? (
                <MenuItem disabled>
                  <Typography color="error">
                    Failed to load months and years
                  </Typography>
                </MenuItem>
              ) : monthsAndYears.length === 0 ? (
                <MenuItem disabled>
                  <Typography>No months and years available</Typography>
                </MenuItem>
              ) : (
                monthsAndYears.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {`${item.month} ${item.year}`}
                  </MenuItem>
                ))
              )}
            </TextField>

            {/* <TextField
              select
              name="paid_by"
              value={selectedLandlordPayment.paid_by}
              onChange={handleInputChange}
              label="Paid By (Manager)"
              helperText={errors.paid_by}
              error={Boolean(errors.paid_by)}
            >
              {isLoadingManagers ? (
                <MenuItem disabled>
                  <CircularProgress size={24} />
                </MenuItem>
              ) : errorManagers ? (
                <MenuItem disabled>
                  <Typography color="error">Failed to load managers</Typography>
                </MenuItem>
              ) : managers.length === 0 ? (
                <MenuItem disabled>
                  <Typography>No managers available</Typography>
                </MenuItem>
              ) : (
                managers.map((manager) => (
                  <MenuItem key={manager.id} value={manager.related_user.id}>
                    {`${manager.related_user.first_name} ${manager.related_user.last_name}`}
                  </MenuItem>
                ))
              )}
            </TextField> */}
          </Box>
        </DialogContent>

        {/* Dialog Actions */}
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{ color: "red", fontSize: 14 }}
          >
            Cancel
          </Button>
          {isSubmittingData ? (
            <LoadingButton
              variant="filled"
              loading
              startIcon={<SaveIcon />}
              loadingPosition="start"
              sx={{ color: "#188c0b", fontSize: 14 }}
            >
              Editing Data ...
            </LoadingButton>
          ) : (
            <Button
              variant="outlined"
              onClick={handleSubmit}
              sx={{ color: "#188c0b", fontSize: 14 }}
            >
              Edit
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
